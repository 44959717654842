<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group>
          <h6 class="text-primary">
            Centro de Mando P&M
          </h6>
          <b-form-input
            :value="gabinete.gabinete.poste"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group>
          <h6 class="text-primary">
            Fecha Creacion
          </h6>
          <b-form-input
            :value="formatFecha(gabinete.fechaCreacion)"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group>
          <h6 class="text-primary">
            Brigada
          </h6>
          <b-form-input
            :value="gabinete.brigada.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="5"
      >
        <b-form-group>
          <h6 class="text-primary">
            Localidad
          </h6>
          <b-form-input
            :value="gabinete.localidad.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="7"
      >
        <b-form-group>
          <h6 class="text-primary">
            Lugar
          </h6>
          <b-form-textarea
            :value="gabinete.lugar"
            disabled
            rows="2"
            no-resize
          />
        </b-form-group>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col cols="12">
        <h3 class="text-primary">
          Trabajos Realizados
          <hr>
        </h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="gabinete.trabajosPreventivo.length > 0"
        cols="12"
        sm="6"
      >
        <b-form-group>
          <h6 class="text-primary">
            Preventivo
          </h6>
          <b-form-input
            :value="getTrabajosPreventivos()"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="gabinete.trabajosCorrectivo.length > 0"
        cols="12"
        sm="6"
      >
        <b-form-group>
          <h6 class="text-primary">
            Correctivo
          </h6>
          <b-form-input
            :value="getTrabajosCorrectivos()"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="gabinete.trabajoRealizado && gabinete.trabajoRealizado === 'Mantenimiento Preventivo'"
        cols="12"
        sm="6"
      >
        <b-form-group>
          <h6 class="text-primary">
            Mantenimiento Preventivo
          </h6>
          <b-form-input
            :value="gabinete.preventivo"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="gabinete.trabajoRealizado && gabinete.trabajoRealizado === 'Mantenimiento Correctivo'"
        cols="12"
        sm="6"
      >
        <b-form-group>
          <h6 class="text-primary">
            Mantenimiento Correctivo
          </h6>
          <b-form-input
            :value="gabinete.correctivo"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="12"
      >
        <b-form-group>
          <h6 class="text-primary">
            Observaciones
          </h6>
          <b-form-textarea
            :value="gabinete.observaciones"
            rows="2"
            disabled
            no-resize
          />
        </b-form-group>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="gabinete.seguimiento"
          style-class="vgt-table condensed"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field == 'detalle'">
              <b-button
                v-if="props.row.url"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="gradient-primary"
                @click="mostrarImagen(props.row)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
            </span>
            <span v-else-if="props.column.field == 'fechaHora'">
              <span>{{ formatFecha(props.row.fechaHora) }}</span>
            </span>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
    <b-modal
      v-model="verDialogoDocumento"
      ok-only
      ok-variant="primary"
      ok-title="Aceptar"
      modal-class="modal-primary"
      centered
      size="lg"
      title="Detalle de la Imagen"
    >
      <b-card-text style="display: flex; justify-content: center">
        <b-img
          :src="urlImagen"
          fluid
          alt="Imagen Cargada')"
          style="max-height: 700px;"
        />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormInput, BFormTextarea,
  BRow, BCol, BFormGroup, BButton, BModal, VBModal, BCardText, BImg,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { calcularFecha } from '@/utils/fechas'

export default {
  components: {
    VueGoodTable,
    BCardText,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BModal,
    BImg,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    gabinete: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      urlImagen: '',
      verDialogoDocumento: false,
      columns: [
        {
          label: 'Tipo',
          field: 'tipo',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Fecha/Hora',
          field: 'fechaHora',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
    }
  },
  methods: {
    mostrarImagen(file) {
      this.urlImagen = ''
      this.urlImagen = file.url
      this.verDialogoDocumento = true
    },
    formatFecha(row) {
      return calcularFecha(row)
    },
    getTrabajosPreventivos() {
      return this.gabinete.trabajosPreventivo.map(trabajo => trabajo.title).join(', ')
    },
    getTrabajosCorrectivos() {
      return this.gabinete.trabajosCorrectivo.map(trabajo => trabajo.title).join(', ')
    },
  },
}
</script>

<style scoped>

</style>
